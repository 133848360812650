import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import store from 'store/configureStore'
import { GetContext } from 'store/storeUtils'
import { OnRequestChangeCallback } from '../services/APIs/BaseAPI'
import {
  AddKeywordsToPartsArgs,
  KeywordDto,
  KeywordGroupDto,
  MaterialsAndStandardsDto,
  StandardKeywordDto,
} from '../services/APIs/InternalAPI/internal-api.contracts'
import { MaterialsAPI } from '../services/APIs/InternalAPI/MaterialsAPI'
import { BaseController } from './BaseController'

// export type SelectableKeywords = Record<string, string[]>

export interface IMaterialsAPI {
  SaveKeyword: (
    keywords: StandardKeywordDto[]
  ) => Promise<MaterialsAndStandardsDto[]>
  GetStandardsAndMaterials: () => Promise<MaterialsAndStandardsDto[]>
  AddKeywordsToParts: (
    projectId: string,
    args: AddKeywordsToPartsArgs
  ) => Promise<void>

  GetSelectableKeywordsForAssembly: (
    projectId: string,
    assemblyId: string,
    selectedKeywords: string[]
  ) => Promise<KeywordGroupDto>

  GetSelectableKeywordsForProject: (
    projectId: string,
    selectedKeywords: string[]
  ) => Promise<KeywordGroupDto>
}

export enum MaterialsOperations {
  GetMaterials = 'GetMaterials',
  GetStandardsAndMaterials = 'GetStandardsAndMaterials',
  SaveKeyword = 'SaveKeyword',
}

export class MaterialsController extends BaseController<IMaterialsAPI> {
  constructor(
    apiFactory?: (onRequestChange: OnRequestChangeCallback) => IMaterialsAPI
  ) {
    super(
      apiFactory
        ? apiFactory
        : (onRequestChanged) => {
            const { partyId } = GetContext()

            return new MaterialsAPI(partyId, onRequestChanged)
          }
    )
  }

  private removeArticleCounter(token: string) {
    const parentesisIndex = token.lastIndexOf('(')

    return parentesisIndex > 0
      ? token.substring(0, parentesisIndex).trim()
      : token
  }

  public async GetStandardsAndMaterials() {
    const resp = await this.api.GetStandardsAndMaterials()

    return resp
  }

  public async SaveMultipleKeywords(
    changedMaterialGroups: Record<string, Record<string, StandardKeywordDto>>
  ) {
    try {
      const keywordsToSend: StandardKeywordDto[] = []

      Object.keys(changedMaterialGroups).forEach((materialGroup) => {
        Object.keys(changedMaterialGroups[materialGroup]).forEach((keyword) => {
          keywordsToSend.push(changedMaterialGroups[materialGroup][keyword])
        })
      })

      const resp = await this.api.SaveKeyword(keywordsToSend)

      return resp
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetKeywordVisible(
    keyword: StandardKeywordDto,
    checked: boolean
  ) {
    const resp = await this.api.SaveKeyword([
      { ...keyword, isSelected: checked },
    ])

    return resp
  }

  // public async GetMaterialsAsync(
  //   tokens: string[],
  //   groupOrderFilter?: string,
  //   isPurchasingPart?: boolean,
  //   bomItemWorkingSteps?: WorkingStepType[],
  //   showGroups?: string[]
  // ) {
  //   const tokensWithoutCounters = tokens.map(this.removeArticleCounter)
  //   const { partyId, projectId } = GetContext()

  //   let resp: Partial<MaterialSelectorDto>

  //   if (!projectId) {
  //     resp = await this.api.GetMaterialsAsync(tokensWithoutCounters)
  //   } else {
  //     resp = await new ProjectsAPI(partyId).GetMaterialTokensInProjectContext(
  //       projectId,
  //       tokensWithoutCounters,
  //       isPurchasingPart,
  //       bomItemWorkingSteps,
  //       this.onRequestChange
  //     )
  //   }

  //   if (showGroups) {
  //     Object.keys(resp.selectableTokens).forEach((group) => {
  //       if (!showGroups.includes(group)) {
  //         delete resp.selectableTokens[group]
  //       }
  //     })

  //     return resp
  //   }

  //   if (groupOrderFilter) {
  //     return Object.assign(resp, {
  //       selectableTokens: {
  //         [groupOrderFilter]: resp.selectableTokens[groupOrderFilter],
  //       },
  //     })
  //   }

  //   return resp
  // }

  public async AddKeywordsToRows(args: {
    keywords: KeywordDto[]
    bomItemPointers?: BomItemPointer[]
    allFilteredItems?: boolean
  }) {
    try {
      const { projectId } = GetContext()

      if (!projectId) {
        return
      }

      let rowIds: string[] = []

      if (args.allFilteredItems && !args.bomItemPointers?.length) {
        rowIds = projectSelectors.allFilteredPartTypeIds(store.getState())
      } else {
        rowIds = args.bomItemPointers.map((x) => x.id)
      }

      await this.api.AddKeywordsToParts(projectId, {
        keywords: args.keywords.map((x) => x.originalKeyword),
        rowIds: rowIds,
      })
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetSelectableKeywordsForAssembly(
    assemblyId: string,
    selectedKeywords: string[]
  ): Promise<Record<string, KeywordDto[]>> {
    try {
      const { projectId } = GetContext()

      if (!projectId) {
        return null
      }

      const resp = await this.api.GetSelectableKeywordsForAssembly(
        projectId,
        assemblyId,
        selectedKeywords
      )

      // to keep compatibility, will be renamed to keywordGroups later
      return resp.keywordGroups
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetSelectableKeywordsForProject(
    selectedKeywords: string[]
  ): Promise<Record<string, KeywordDto[]>> {
    try {
      const { projectId } = GetContext()

      if (!projectId) {
        return null
      }

      const resp = await this.api.GetSelectableKeywordsForProject(
        projectId,
        selectedKeywords
      )

      return resp.keywordGroups
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  // private sortMaterialGroups(keywordGroups: { [group: string]: KeywordDto[] }) {
  //   const dimensionsGroup = keywordGroups['dimensions']
  //   if (dimensionsGroup) {
  //     const collator = new Intl.Collator(undefined, {
  //       numeric: true,
  //       sensitivity: 'base',
  //     })
  //     keywordGroups['dimensions'] = dimensionsGroup
  //       .sort(collator.compare) // sort by numbers
  //       .sort((a, b) => {
  //         // sort by measure (thick, outer, weight, etc)
  //         const measureA = a.split(' ').pop()
  //         const measureB = b.split(' ').pop()

  //         return measureA < measureB ? -1 : 1
  //       })
  //   }
  // }
}
