import { parse as parseContentDisposition } from '@tinyhttp/content-disposition'
import Axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios'
import { NetworkActions } from 'features/SignalR/store/ConnectionReducer'
import _ from 'lodash'
import { TelemetryService } from 'services/Telemetry'
import store from 'store/configureStore'
import { AuthService } from '../auth'
import { HttpGetMetaData } from './InternalAPI/InternalAPI'

export interface APIResponse<T> {
  records: T[]
  _meta?: {
    page: number
    pageSize: number
    totalRecords: number
    totalPages: number
  }
}

export type RequestStatus = {
  id: string
  isLoading: boolean
  error: {
    message: string
    validation?: Record<string, string>
    status: number
  }
  validation: { [validationKey: string]: string }
  progress?: number
}

export type OnRequestChangeCallback = (
  requestStatus: Partial<RequestStatus>
) => void

type RequestAllowedData<T> = Array<T> | Record<string, T> | File[] | unknown

export type Request = {
  id?: string
  relativePath: string
  onRequestChange?: OnRequestChangeCallback
  data?: unknown
  onUploadProgress?: (progress) => void
  params?: Record<string, unknown>
  headers?: Record<string, string>
  uri?: string
  _meta?: HttpGetMetaData<never>
}

export abstract class BaseAPI {
  protected relativePath = '/'
  private source = Axios.CancelToken.source()

  public CancelRequests() {
    this.source.cancel('user cancelled')
    this.source = Axios.CancelToken.source()
  }

  constructor(
    protected baseAddress?,
    private onRequestChangeCallback?: OnRequestChangeCallback
  ) {}

  protected GetFunctionUrl = (relativePath: string) => {
    const functionUrl =
      this.baseAddress +
      (this.relativePath === '/' ? '' : this.relativePath) +
      relativePath

    if (functionUrl.endsWith('/')) {
      return functionUrl.slice(0, -1)
    } else {
      return functionUrl
    }
  }

  public authorizationHeader = async (headers?: Record<string, string>) => {
    const _headers = { ...headers } || {}

    const accessToken = await AuthService.GetCurrent()?.GetAccessToken()

    if (accessToken) {
      _headers['Authorization'] = `Bearer ${accessToken}`
    }

    return _headers
  }

  private globalOnRequestChange = (requestStatus: Partial<RequestStatus>) => {
    try {
      store.dispatch(
        NetworkActions.SetLatestRequest({ request: requestStatus })
      )
    } catch (err) {
      console.error('globalOnRequestChange error', err)
    }
  }

  private notifyRequestChanged = (
    requestStatus: Partial<RequestStatus>,
    onRequestChange: OnRequestChangeCallback
  ) => {
    if (onRequestChange) {
      onRequestChange(requestStatus)
    } else if (this.onRequestChangeCallback) {
      this.onRequestChangeCallback(requestStatus)
    }

    this.globalOnRequestChange(requestStatus)
  }

  private async SendRequest<
    responseDataType extends RequestAllowedData<responseDataType>
  >(req: Request, config: AxiosRequestConfig) {
    let resp: AxiosResponse<unknown>
    try {
      this.source = Axios.CancelToken.source()

      if (config.url.includes('undefined')) {
        console.warn('undefined in url', config.url)
        return null
      }

      const traceHeaders =
        TelemetryService.getInstance().getCorrelationHeaders()

      if (traceHeaders) {
        config.headers = {
          ...(config.headers || {}),
          ...traceHeaders,
        }
      }

      this.notifyRequestChanged(
        {
          isLoading: true,
          id: req.id,
        },
        req.onRequestChange
      )

      resp = await Axios({ ...config, cancelToken: this.source.token })

      this.notifyRequestChanged(
        {
          isLoading: false,
          id: req.id,
        },
        req.onRequestChange
      )

      return resp.data as responseDataType
    } catch (err) {
      // console.info('sendRequest err', JSON.stringify(err))
      const errorData = this.getErrorData(err as AxiosError<never>)

      this.notifyRequestChanged(
        {
          isLoading: false,
          id: req.id,
          error: errorData,
        } as RequestStatus,
        req.onRequestChange
      )

      if (errorData.message.toLowerCase() === 'user cancelled') {
        return null
      }
      return Promise.reject(errorData)
    }
  }

  protected OnRequestChangeWithId(
    operationId: string,
    req: Partial<RequestStatus>
  ) {
    return {
      ...req,
      id: operationId,
    }
  }

  protected async GetAsync<
    responseDataType extends RequestAllowedData<responseDataType>
  >(req: Request) {
    try {
      const data = await this.SendRequest(req, {
        method: 'GET',
        url: req.uri ?? this.GetFunctionUrl(req.relativePath),
        params: req.params,
        headers: await this.authorizationHeader(req.headers),
      })

      return data as responseDataType
    } catch (err) {
      return Promise.reject(err)
    }
  }

  protected async GetPagedAsync<
    responseDataType extends RequestAllowedData<responseDataType>
  >(req: Request) {
    try {
      const data = await this.SendRequest(req, {
        method: 'GET',
        url: req.uri ?? this.GetFunctionUrl(req.relativePath),
        params: {
          ...req.params,
          ...req._meta,
          orderBy: req._meta?.orderBy
            ? _.upperFirst(req._meta?.orderBy.toString()) +
              ' ' +
              (req._meta?.orderDirection || 'desc')
            : null,
          orderDirection: undefined,
        },
        headers: await this.authorizationHeader(req.headers),
      })

      return data as responseDataType
    } catch (err) {
      return Promise.reject(err)
    }
  }

  protected async GetWithResponseTypeAsync<
    responseDataType extends RequestAllowedData<responseDataType>
  >(req: Request, responseType: ResponseType) {
    try {
      const data = await this.SendRequest(req, {
        method: 'GET',
        url: req.uri ?? this.GetFunctionUrl(req.relativePath),
        params: req.params,
        headers: await this.authorizationHeader(req.headers),
        responseType: responseType,
      })

      return data as responseDataType
    } catch (err) {
      return Promise.reject(err)
    }
  }

  protected async PutAsync<
    responseDataType extends RequestAllowedData<responseDataType>
  >(req: Request) {
    try {
      const response = await this.SendRequest<responseDataType>(req, {
        method: 'PUT',
        url: req.uri ?? this.GetFunctionUrl(req.relativePath || '/'),
        headers: await this.authorizationHeader(req.headers),
        data: req.data,
        params: req.params,
      })

      return (
        (response?.['result'] as responseDataType) ??
        (response as responseDataType)
      )
    } catch (err) {
      return Promise.reject(err)
    }
  }

  protected async PostAsync<
    responseDataType extends RequestAllowedData<responseDataType>
  >(req: Request) {
    try {
      const config = {
        method: 'POST',
        url: req.uri ?? this.GetFunctionUrl(req.relativePath || '/'),
        headers: await this.authorizationHeader(req.headers),
        data: req.data,
      }

      const response = await this.SendRequest(req, config)

      return response as responseDataType
    } catch (err) {
      // console.log('PostAsync error', err)
      return Promise.reject(err)
    }
  }

  protected async DeleteAsync(req: Request) {
    try {
      await this.SendRequest(req, {
        method: 'DELETE',
        data: req.data,
        url: req.uri ?? this.GetFunctionUrl(req.relativePath),
        headers: await this.authorizationHeader(req.headers),
        params: req.params,
      })

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  private getErrorData(
    err: AxiosError<{
      message?: string
      detail?: string
      validation?: Record<string, string>
      Errors?: Record<string, string>
      errors?: Record<string, string>
    }>
  ): {
    message: string
    validation?: Record<string, string>
    status: number
  } {
    const errorObj = {
      message:
        err.response?.data?.message ||
        err.response?.data?.detail ||
        (err.response?.status === 403 && 'action not allowed') ||
        err.response?.statusText ||
        err.message ||
        err.toString(),
      validation:
        err.response?.data?.validation ||
        err.response?.data?.Errors ||
        err.response?.data?.errors,
      status: err.response?.status,
    }

    return errorObj
  }

  protected async UploadWithData<T>(req: Request) {
    return await this.UploadAsync<T>(req, 'args')
  }

  private appendFileDetailFieldToFormData(
    formData: FormData,
    parameterName: string,
    index: number,
    dataObject: Record<string, string | Blob>,
    key: string
  ) {
    const field = dataObject[key]

    if (field instanceof File) {
      formData.append(`${parameterName}[${index}].${key}`, field as File)
    } else if (field instanceof Array) {
      field.forEach((item, i) => {
        formData.append(`${parameterName}[${index}].${key}[${i}]`, item)
      })
    } else if (field instanceof Object) {
      formData.append(
        `${parameterName}[${index}].${key}`,
        JSON.stringify(field)
      )
    } else if (field) {
      formData.append(`${parameterName}[${index}].${key}`, field)
    }
  }

  private getFileUploadBody(
    data: Array<File | Record<string, unknown>>,
    parameterName = 'files'
  ) {
    let form: FormData

    if (data && data instanceof Array) {
      if (data[0] instanceof File) {
        form = new FormData()
        data.forEach((file) => form.append(parameterName, file as File))
      } else {
        form = new FormData()
        data.forEach((record, index) =>
          Object.keys(record).forEach((key) => {
            this.appendFileDetailFieldToFormData(
              form,
              parameterName,
              index,
              record as Record<string, string | Blob>,
              key
            )
          })
        )
      }
    }

    return form || data
  }

  protected async UploadAsync<T>(
    req: Request,
    controllerParameterName = 'geometryFiles'
  ) {
    try {
      const data = this.getFileUploadBody(
        req.data as [],
        controllerParameterName
      )

      req.onUploadProgress && req.onUploadProgress(0)

      const response = await this.SendRequest<T>(req, {
        method: 'POST',
        url: req.uri || this.GetFunctionUrl(req.relativePath),
        data: data,
        params: req.params,
        headers: await this.authorizationHeader(req.headers),
        onUploadProgress: (progress: AxiosProgressEvent) => {
          const p =
            (progress.total > 0 &&
              progress.total >= progress.loaded &&
              (progress.loaded / progress.total) * 100) ||
            undefined
          req.onRequestChange &&
            req.onRequestChange({
              ...req,
              progress: p,
            })
          req.onUploadProgress && req.onUploadProgress(p)
        },
      })

      req.onRequestChange &&
        req.onRequestChange({
          ...req,
        })
      req.onUploadProgress && req.onUploadProgress(undefined)

      return response
    } catch (err) {
      return Promise.reject(this.getErrorData(err as AxiosError<never>))
    }
  }

  protected async DownloadBlob(req: Request) {
    try {
      req.onRequestChange &&
        req.onRequestChange({ isLoading: true, id: req.id })

      const response = await Axios.get(this.GetFunctionUrl(req.relativePath), {
        responseType: 'blob',
        headers: await this.authorizationHeader(),
        params: req.params,
        cancelToken: this.source.token,
      })

      return response.data as Blob | MediaSource
    } catch (ex) {
      console.error('DownloadBlob error', ex)
      throw ex
    } finally {
      req.onRequestChange &&
        req.onRequestChange({ isLoading: false, id: req.id })
    }
  }

  protected async DownloadAsync(req: Request, usePost = false) {
    try {
      req.onRequestChange &&
        req.onRequestChange({ isLoading: true, id: req.id })

      let response: AxiosResponse<never, never>

      if (usePost) {
        response = await Axios.post(
          this.GetFunctionUrl(req.relativePath),
          req.data,
          {
            responseType: 'arraybuffer',
            headers: await this.authorizationHeader(),
            params: req.params,
          }
        )
      } else {
        response = await Axios.get(this.GetFunctionUrl(req.relativePath), {
          responseType: 'arraybuffer',
          headers: await this.authorizationHeader(),
          params: req.params,
        })
      }

      if (response.status === 204) {
        // no content
        console.warn(response)
        return false
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url

      let fileName = 'resource.zip'

      if (response.headers['content-disposition']) {
        const cd = parseContentDisposition(
          response.headers['content-disposition']
        )
        fileName = cd.parameters['filename'] as string
      } else {
        console.warn('content-disposition header not found')
      }

      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      req.onRequestChange &&
        req.onRequestChange({ isLoading: false, id: req.id })

      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.info(err.response.data)
        console.info(err.response.status)
        console.info(err.response.headers)
      }
      req.onRequestChange &&
        req.onRequestChange({ isLoading: false, id: req.id, error: err })

      return Promise.reject(this.getErrorData(err as AxiosError<never>))
    }
  }
}
