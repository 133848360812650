import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  FeaturesDto,
  ManuallySetAdditionalCostsArgs,
  ManuallySetProductionTimeArgs,
  NestingDirection,
  PriceRowsDto,
  ProjectSimplifyDto,
  ResourceSummaryDto,
  RowDto,
  SelectBoMItemUpperSideRequest,
  SetKeywordsOrArticleToPartsArgs,
  SetMaterialCostPriceArgs,
  SetPrecisionsArgs,
  UpdateBomItemActivityCommentRequest,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureType, IBomItemAPI } from './BomItemController'

export class BomItemAPI extends InternalAPI implements IBomItemAPI {
  constructor(
    organizationId: string,
    projectId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super()
    this.relativePath = `/api/parties/${organizationId}/projects/${projectId}`
  }
  GetItemDetails: (itemId: string) => Promise<FeaturesDto>

  public async setBomItemPrecisions(args: SetPrecisionsArgs) {
    return await this.PutAsync<void>({
      id: 'set-precisions',
      relativePath: `/items/precisions`,
      data: args,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetNestingDirection(
    itemId: string,
    nestingDirection: NestingDirection
  ) {
    return await this.PutAsync<void>({
      id: `${itemId}.nesting`,
      relativePath: `/items/${itemId}/nesting`,
      data: { nestingDirection },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetFilteredFeatures(itemId: string, featureType: FeatureType) {
    return await this.GetAsync<string>({
      id: 'get-filtered-feature',
      relativePath: `/items/${itemId}/filteredFeatures`,
      params: {
        featureType: featureType,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetItemFeaturesDetails(itemId: string) {
    return await this.GetAsync<FeaturesDto>({
      id: `get-features-${itemId}`,
      relativePath: `/items/${itemId}/details/features`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetItemPriceDetails(itemId: string) {
    return await this.GetAsync<PriceRowsDto>({
      id: `get-price-details-${itemId}`,
      relativePath: `/items/${itemId}/details/price`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetBomItemName(itemId: string, name: string) {
    return await this.PutAsync<void>({
      id: 'bomitem-name',
      relativePath: '/rows/partName',
      data: {
        rowOrAssemblyId: itemId,
        partName: name,
      },
      onRequestChange: this.onRequestChanged,
    })
  }
  public async SetBomQuantity(itemId: string, quantity: number) {
    return await this.PutAsync<void>({
      id: 'bomitem-quantity',
      relativePath: `/items/${itemId}/quantities`,
      data: {
        quantity,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  // public async AddKeywordsToParts(itemIds: string[], keywords: string[]) {
  //   return await this.PostAsync<void>({
  //     id: 'bomitem-keywords',
  //     relativePath: `/materials/AddKeywordsToParts`,
  //     data: {
  //       rowIds: itemIds,
  //       keywords,
  //     } as AddKeywordsToPartsArgs,
  //     onRequestChange: this.onRequestChanged,
  //   })
  // }

  public async SetKeywordsOrArticleToParts(
    rowIds: string[],
    args: SetKeywordsOrArticleToPartsArgs
  ) {
    return await this.PostAsync<void>({
      id: 'bomitem-keywords',
      relativePath: `/materials/SetKeywordsOrArticleToParts`,
      data: {
        rowIds,
        ...args,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetAsPurchasingItem(bomItemId: string) {
    return await this.PostAsync<void>({
      id: 'saving-purchasing-item',
      relativePath: `/items/${bomItemId}/purchasing`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetBomItem(bomItemId: string) {
    return await this.GetAsync<RowDto>({
      id: `loading-bomItem-${bomItemId}`,
      relativePath: `/items/${bomItemId}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async UnsetAsPurchasingItem(bomItemId: string) {
    return await this.DeleteAsync({
      id: 'saving-purchasing-item',
      relativePath: `/items/${bomItemId}/purchasing`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetPartTypeGroups() {
    return await this.GetAsync<ProjectSimplifyDto>({
      id: 'get-part-type-groups',
      relativePath: `/parttypegroups`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetPartTypeGroups(assemblyId: string, partIds: string[]) {
    return await this.PostAsync<void>({
      id: 'save-part-type-groups',
      relativePath: `/parttypegroups`,
      data: {
        assemblyId,
        partIds,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetSelectableResources(
    bomItemId: string,
    workingStepType: WorkingStepType
  ) {
    return await this.GetAsync<ResourceSummaryDto[]>({
      id: `get-selectable-resources-${bomItemId}-${workingStepType}`,
      relativePath: `/items/${bomItemId}/resources`,
      params: {
        workingStepType,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetSelectedResource(
    bomItemIds: string[],
    workingStepType: WorkingStepType,
    resourceId: string
  ) {
    return this.PutAsync<void>({
      id: `set-resource-${bomItemIds[0]}-${workingStepType}`,
      relativePath: `/items/resource`,
      data: {
        bomItemIds,
        workingStepType,
        resourceId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async Get3DCdxfbFile(itemId: string, fileName: string) {
    return await this.GetWithResponseTypeAsync<ArrayBuffer>(
      {
        id: `get-cdx-file`,
        relativePath: `/items/${itemId}/GetCadExViewer3DFile/${fileName}`,
        onRequestChange: this.onRequestChanged,
      },
      'arraybuffer'
    )
  }

  public async Get2DCdxfbFile(itemId: string, fileName: string) {
    return await this.GetWithResponseTypeAsync<ArrayBuffer>(
      {
        id: `get-cdx-file`,
        relativePath: `/items/${itemId}/GetCadExViewer2DFile/${fileName}`,
        onRequestChange: this.onRequestChanged,
      },
      'arraybuffer'
    )
  }

  public async ManualSetProductionTime(
    itemId: string,
    req: ManuallySetProductionTimeArgs
  ) {
    return await this.PutAsync<void>({
      relativePath: `/items/${itemId}/manual-set-production-time`,
      data: req,
      id: `manual-estimation-${req.primaryWorkingStep}-${req.secondaryWorkingStep}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async DeleteManualSetProductionTime(
    itemId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType
  ) {
    return await this.DeleteAsync({
      relativePath: `/items/${itemId}/manual-set-production-time/${primaryWorkingStep}/${secondaryWorkingStep}`,
      id: `delete-manual-production-time-${primaryWorkingStep}-${secondaryWorkingStep}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async ManualSetAdditionalCosts(
    itemId: string,
    req: ManuallySetAdditionalCostsArgs
  ) {
    return await this.PutAsync<void>({
      relativePath: `/items/${itemId}/manual-set-additional-costs`,
      data: req,
      id: `manual-set-additional-costs-${req.primaryWorkingStep}-${req.secondaryWorkingStep}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async DeleteManualSetAdditionalCosts(
    itemId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType
  ) {
    return await this.DeleteAsync({
      relativePath: `/items/${itemId}/manual-set-additional-costs/${primaryWorkingStep}/${secondaryWorkingStep}`,
      id: `delete-manual-additional-costs-${primaryWorkingStep}-${secondaryWorkingStep}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetMaterialCostPrice(
    itemId: string,
    req: SetMaterialCostPriceArgs
  ) {
    return await this.PutAsync<void>({
      relativePath: `/items/${itemId}/financials/materials/cost-price`,
      data: req,
      id: `set-material-cost-price-${itemId}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async DeleteMaterialCostPrice(itemId: string) {
    return await this.DeleteAsync({
      relativePath: `/items/${itemId}/financials/materials/cost-price`,
      id: `delete-material-cost-price-${itemId}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async CheckManufacturability(
    itemId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType
  ) {
    return await this.PostAsync<void>({
      relativePath: `/items/${itemId}/check-manufacturability/${primaryWorkingStep}/${secondaryWorkingStep}`,
      id: `check-manufacturability-${itemId}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SwitchManufacturabilityFlag(itemId: string) {
    return await this.PutAsync<void>({
      relativePath: `/items/${itemId}/manual-set-check-manufacturability`,
      id: `switch-manufacturability-${itemId}`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async updateComment(itemId: string, comment: string) {
    return await this.PutAsync<void>({
      id: `update-comment-${itemId}`,
      relativePath: `/items/${itemId}/comment`,
      data: {
        comment,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async updateActivityComment(
    itemId: string,
    req: UpdateBomItemActivityCommentRequest
  ) {
    return await this.PutAsync<void>({
      id: `update-activity-comment-${req.boMItemActivityId}`,
      relativePath: `/items/${itemId}/comment/updateActivityComment`,
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async downloadBomItemFiles(itemId: string) {
    return await this.DownloadAsync({
      id: `download-bom-item-files-${itemId}`,
      relativePath: `/items/${itemId}/files`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async downloadAssemblyFiles(itemId: string) {
    return await this.DownloadAsync({
      id: `download-bom-item-files-${itemId}`,
      relativePath: `/assemblies/${itemId}/files`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async downloadProjectFiles(itemId: string) {
    return await this.DownloadAsync({
      id: `download-bom-item-files-${itemId}`,
      relativePath: `/${itemId}/files`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async solveIssues(itemIds: string[], issueCodes: string[]) {
    return await this.PostAsync<void>({
      id: `solve-issues-${itemIds[0]}-${issueCodes.join('-')}`,
      relativePath: `/items/solve-issues`,
      data: {
        bomItemIds: itemIds,
        issueCodes,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async convertSubAssemblyToPurchasingPart(itemId: string) {
    return await this.PostAsync<void>({
      id: `convert-sub-assembly-to-purchasing-part-${itemId}`,
      relativePath: `/items/${itemId}/purchasing/convert`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetActivityArticles(
    boMItemId: string,
    boMItemActivityId: string,
    articles: string[]
  ) {
    return await this.PutAsync<void>({
      id: `set-activity-articles`,
      relativePath: `/articles/SetActivityArticles`,
      data: {
        boMItemId,
        boMItemActivityId,
        articles,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async SetBomItemUpperSide(
    itemId: string,
    req: SelectBoMItemUpperSideRequest
  ) {
    return this.PutAsync<void>({
      id: `set-upper-side-${itemId}`,
      relativePath: `/items/${itemId}/select-upper-side`,
      data: req,
    })
  }
}
