export function expect(truthy: boolean, message: string, ...args: unknown[]) {
  if (!truthy) {
    console.error(message, args)

    if (process.env.NODE_ENV === 'development') {
      debugger
      throw new Error(message)
    }
  }
}
