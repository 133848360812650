import { BaseController } from 'controllers/BaseController'
import { NavigateFunction } from 'react-router'
import {
  CreateProjectRequest,
  ProjectDto,
  SetEstimatedDeliveryDateRequest,
  SetEstimatedDeliveryDaysRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { GetContext } from 'store/storeUtils'
import { ProjectsAPI } from './ProjectsAPI'

export interface IProjectsAPI {
  CreateNewProject: (args: CreateProjectRequest) => Promise<string>
  SetEstimatedDeliveryDate: (
    projectId: string,
    req: SetEstimatedDeliveryDateRequest
  ) => Promise<void>
  SetEstimatedDeliveryDays: (
    projectId: string,
    req: SetEstimatedDeliveryDaysRequest
  ) => Promise<void>
  GetProjectInfo: (projectId: string) => Promise<ProjectDto>
}

export class ProjectsController extends BaseController<IProjectsAPI> {
  public partyId: string

  constructor() {
    let _partyId: string

    super((onRequestChange) => {
      const { partyId } = GetContext()

      _partyId = partyId

      return new ProjectsAPI(partyId, onRequestChange)
    })

    this.partyId = _partyId
  }

  public async CreateNewProject(
    args: CreateProjectRequest,
    navigate: NavigateFunction
  ): Promise<string> {
    try {
      const response = await this.api.CreateNewProject(args)

      if (navigate) {
        navigate(`/app/project/${encodeURI(response)}`)
      } else {
        window.location.assign(`/app/project/${encodeURI(response)}`)
      }
      return response
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetEstimatedDeliveryDate(
    projectId: string,
    req: SetEstimatedDeliveryDateRequest
  ): Promise<void> {
    try {
      await this.api.SetEstimatedDeliveryDate(projectId, req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetEstimatedDeliveryDays(
    projectId: string,
    req: SetEstimatedDeliveryDaysRequest
  ): Promise<void> {
    try {
      await this.api.SetEstimatedDeliveryDays(projectId, req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetProjectInfo(projectId) {
    try {
      return await this.api.GetProjectInfo(projectId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
