import { useCallback } from 'react'
import { ShowException } from 'store/Application/appActions'

export function useShowException(pageTitle: string) {
  return useCallback(
    function ShowAppException(error: unknown) {
      ShowException(pageTitle, error)
    },
    [pageTitle]
  )
}
