import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'
import { BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ProjectState } from 'store/Project/ProjectTypes'
import { expect } from 'utils/expect'
import { updateHeadersFilteredParts } from '../filters/updateHeadersFilteredParts'
import { bomItemSelector } from '../selectors/bomItemSelector'

/**
 * delete multiple BOM items from Redux store
 */
export const deleteBomItemsFromStore: CaseReducer<
  ProjectState,
  PayloadAction<BomItemPointer[]>
> = (state, action) => {
  expect(action.payload.length > 0, 'no bom items to delete')

  if (!action.payload.length) {
    return
  }

  action.payload.forEach((bomItemPointer) => {
    DeleteFromReferences(state, bomItemPointer)
  })

  updateHeadersFilteredParts(state)

  state.selectedBomItemPointers = state.selectedBomItemPointers.filter(
    (x) => !action.payload.some((y) => y.id === x.id)
  )

  state.selectedBomItemPointers = state.selectedBomItemPointers.filter(
    (x) => !action.payload.some((y) => y.id === x.id)
  )
}

/**
 * Delete a BOM item from Redux store.
 * The BOM item references should be removed from all the headers, so it is not shown in the UI
 * @param state The Redux state
 * @param action action.payload is the pointer to the BOM item to delete
 */
export const deleteBomItemFromStore: CaseReducer<
  ProjectState,
  PayloadAction<BomItemPointer>
> = (state, action) => {
  // Remove the BOM item from the selectedBomItemPointers array
  state.selectedBomItemPointers = state.selectedBomItemPointers.filter(
    (x) => x.id !== action.payload.id
  )

  // Reset the focusedBomItemPointer and searchedBomItemPointer to null
  state.focusedBomItemPointer = null
  state.searchedBomItemPointer = null

  DeleteFromReferences(state, action.payload)

  updateHeadersFilteredParts(state)

  return state
}

function DeleteFromReferences(
  state: WritableDraft<ProjectState>,
  bomItemPointer: BomItemPointer
) {
  switch (bomItemPointer.type) {
    case BomItemType.partType: {
      RemovePartTypeFromHeaders(state, bomItemPointer)

      if (state?.partTypes?.[bomItemPointer.id]) {
        delete state.partTypes[bomItemPointer.id]
        state.partTypeIds = state.partTypeIds.filter(
          (id) => id !== bomItemPointer.id
        )
      }

      break
    }
    case BomItemType.partInstance: {
      delete state.partInstances[bomItemPointer.id]
      state.partInstanceIds = state.partInstanceIds.filter(
        (id) => id !== bomItemPointer.id
      )
      break
    }
    case BomItemType.assemblyInstance: {
      delete state.assemblyInstances[bomItemPointer.id]
      state.assemblyInstancesIds = state.assemblyInstancesIds.filter(
        (id) => id !== bomItemPointer.id
      )
      break
    }
    case BomItemType.assemblyType: {
      // needs to delete all the part types and part instances associated with the assembly
      const header = state.assemblyHeaders[bomItemPointer.id]

      if (!header) {
        return
      }

      header?.partTypePointers.forEach((pointer) =>
        DeleteFromReferences(state, pointer)
      )
      header?.partInstancePointers.forEach((pointer) =>
        DeleteFromReferences(state, pointer)
      )
      header?.subAssembliesPointers.forEach((pointer) =>
        DeleteFromReferences(state, pointer)
      )

      delete state?.assemblyHeaders?.[bomItemPointer.id]
      state.assemblyHeadersIds = state.assemblyHeadersIds?.filter(
        (id) => id !== bomItemPointer.id
      )
    }
  }
}

function RemovePartTypeFromHeaders(
  state: WritableDraft<ProjectState>,
  bomItemPointer: BomItemPointer
): void {
  state.materialHeadersIds.forEach((x) => {
    const materialHeader = state.materialHeaders[x]
    materialHeader.partTypePointers = materialHeader.partTypePointers.filter(
      (pointer) => pointer.id !== bomItemPointer.id
    )
    materialHeader.filteredPartTypePointers =
      materialHeader.filteredPartTypePointers.filter(
        (pointer) => pointer.id !== bomItemPointer.id
      )
  })

  state.routingHeadersIds.forEach((x) => {
    const routingHeader = state.routingHeaders[x]
    routingHeader.partTypePointers = routingHeader.partTypePointers.filter(
      (pointer) => pointer.id !== bomItemPointer.id
    )
    routingHeader.filteredPartTypePointers =
      routingHeader.filteredPartTypePointers.filter(
        (pointer) => pointer.id !== bomItemPointer.id
      )
  })

  // Remove the part type from its parent assembly, if applicable
  const currentItem = bomItemSelector(
    { project: state },
    bomItemPointer
  ) as PartTypeRow

  if (!currentItem) {
    return
  }

  const parent = bomItemSelector(
    { project: state },
    currentItem?.parentBomItemPointer
  )

  if (parent?.type === BomItemType.assemblyType) {
    parent.filteredPartTypePointers = parent.filteredPartTypePointers.filter(
      (pointer) => pointer.id !== bomItemPointer.id
    )
    parent.partTypePointers = parent.partTypePointers?.filter(
      (pointer) => pointer.id !== bomItemPointer.id
    )

    const partInstancesToDelete = parent.partInstancePointers.filter(
      (x) => state.partInstances[x.id].rowNumber === currentItem.rowNumber
    )

    parent.partInstancePointers = parent.partInstancePointers.filter(
      (pointer) => !partInstancesToDelete.some((x) => x.id === pointer.id)
    )

    parent.filteredPartInstancePointers =
      parent.filteredPartInstancePointers.filter(
        (pointer) => !partInstancesToDelete.some((x) => x.id === pointer.id)
      )

    state.partInstanceIds = state.partInstanceIds.filter(
      (id) => !partInstancesToDelete.some((x) => x.id === id)
    )

    if (parent.partTypePointers.length === 0) {
      // The parent assembly has no more part types, so it should be deleted
      DeleteFromReferences(state, parent)
    }
  }
}
